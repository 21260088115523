import { get, post, postForm } from './http'

// 获取菜单列表
export const getMenus = p => get('/index/user/getMeanList')

// 注册
export const register = p => post('/index/user/register', p);

//获取用户信息
export const getUserInfo = p => get('/index/user/getUserInfo', p);

// 充值记录
export const chargeLog = p => get('/index/user/chargeLog', p);

// 银行卡商品信息
export const cardGoodInfo = p => get('/index/user/cardGoodInfo', p);

// 用户银行卡列表
export const getUserCardList = p => get('/index/user/getUserCardList', p);

// 银行卡详情
export const getCardDetail = p => post('/index/user/getCardDetail', p);

// 国家列表
export const countryList = p => get('/index/user/countryList', p);

// 收货地址添加修改预存
export const addUserAddress = p => post('/index/user/addUserAddress', p);

// 用户收货地址列表
export const getUserAddress = p => get('/index/user/getUserAddress', p);

// 银行卡充值配置
export const cardChargeConfig = p => get('/index/user/cardChargeConfig', p);

// 设置默认银行卡
export const setDefaultCard = p => post('/index/user/setDefaultCard', p);

// 密码修改记录
export const passEditLog = p => get('/index/user/passEditLog', p);

// 修改密码合约前置
export const editPass = p => post('/index/user/editPass', p);

// 上传图片
export const uploadImg = p => postForm('/index/user/uploadImg', p);

// 身份信息预存
export const saveAuth = p => post('/index/user/saveAuth', p);

// 商品列表
export const goodList = p => get('/index/user/goodList', p);

// 商品详情
export const goodDetail = p => get('/index/user/goodDetail', p);

// 用户订单列表
export const orderList = p => post('/index/user/orderList', p);

// 订单详情
export const orderDetail = p => post('/index/user/orderDetail', p);

// 银行卡申请资料
export const saveUserAuth = p => post('/index/user/saveUserAuth', p);

// 国际电话区号
export const getCountryCode = p => get('/index/user/countryCodeList', p);
